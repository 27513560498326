import * as React from 'react';
import SiteButton from '../../SiteButton';
import { SkinButtonProps } from '../SkinButton';
import { ISiteButtonProps } from '../../../SiteButton.types';
import BaseButtonSkin from './BaseButton';
import skinsStyle from './styles/WrappingButton.scss';

const WrappingButtonSkin: React.FC<SkinButtonProps> = props => (
  <BaseButtonSkin {...props} skinsStyle={skinsStyle}></BaseButtonSkin>
);

const WrappingButton: React.FC<Omit<ISiteButtonProps, 'skin'>> = props => (
  <SiteButton {...props} skin={WrappingButtonSkin} />
);

export default WrappingButton;
